<template>
  <div v-if="updateType" class="update-contact">
    <div class="update-contact-body">
      <div class="new-value">
        <h3 v-if="updateType === 'email'">{{ $t('settings.update_dialog.new_email') }}</h3>
        <h3 v-else>{{ $t('settings.update_dialog.new_phone') }}</h3>

        <el-input v-if="updateType === 'email'" placeholder="xxx@email.com" type="text" minlength="2" maxlength="75" v-model="newValue" :size="size" />
        <el-input v-else v-model="newValue" placeholder="0611223344" :size="size" autocomplete="phone" clearable>
          <template #prepend>
            <el-select :placeholder="$t('register.step_1.select')" filterable v-model="phonecountryCode" :size="size" clearable effect="dark">
              <el-option class="phone-country-options" v-for="item in country" :key="item.nom_en_gb" :label="item.nom_en_gb + '(+' + item.phone_code + ')'" :value="item.phone_code" effect="dark">
                <span>
                  {{ item.nom_en_gb + '(+' + item.phone_code + ')' }}
                </span>
              </el-option>
            </el-select>
          </template>
        </el-input>
      </div>
    </div>
    <button @click="checkValues()" class="action-light register-update generic-btn">{{ $t('settings.update_dialog.register') }}</button>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ElMessage } from 'element-plus';
import { inject } from "vue"

export default {
  emits: ['close', 'values'],
  props: {
    updateType: {
      type: String,
      required: false,
      default: null
    },
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      darkmode: inject("darkmode"),
      actualValue: null,
      newValue: null,
      phonecountryCode: null,

      size: 'large'
    };
  },
  watch: {
    updateType() {
      if (this.form && this.updateType === 'email') {
        this.actualValue = this.form.address.email;
      } else {
        this.actualValue = `(+${this.form.address.phone_country_code}) ${this.form.address.phone}`;
      }
    }
  },
  computed: {
    ...mapGetters({
      getLists: 'getLists'
    }),
    country() {
      return this.getLists?.country;
    }
  },
  methods: {
    ...mapActions({
      getEmailPhoneCode: 'getEmailPhoneCode'
    }),
    async sendCode() {
      const payload = {
        type: 0,
        new_value: this.newValue
      };

      if (this.updateType === 'phone') {
        payload.type = 1;
        payload.phone_country_code = this.phonecountryCode;
      }

      await this.getEmailPhoneCode(payload).then(() => {
        const values = {
          type: payload.type,
          newValue: this.newValue
        };

        this.$emit('values', values);
        this.$emit('close');
      });
    },
    checkValues() {
      const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const phoneRegex = /^[0-9]{8,13}$/;
      
      if (this.newValue === this.actualValue || this.newValue === this.form.address.phone) {
        ElMessage({
          message: this.$t(`settings.update_dialog.err_${this.updateType}_2`),
          type: 'warning',
          grouping: true
        });
      } else if ((this.updateType === 'email' && emailRegex.test(this.newValue)) || (this.updateType === 'phone' && phoneRegex.test(this.newValue))) {
        this.sendCode();
      } else {
        ElMessage({
          message: this.$t(`settings.update_dialog.err_${this.updateType}`),
          type: 'warning',
          grouping: true
        });
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/assets/styles/auth.scss';

.update-contact {

  display: flex;
  flex-direction: column;
  align-items: center;

  .update-contact-body {
    margin: 15px 0;
    width: 100%;

    .new-value {

      h3 {
        color: var(--text-color);
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 8px;
      }
    }
  }

  .register-update {
    width: 125px;
    height: 47px;
  }
}

.el-input-group__prepend, .el-input-group__prepend {
  box-shadow: none;
  background: none;
  padding: 0;
  width: 40%;
}
</style>