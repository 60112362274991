<template>
    <div class="kyc-method-history">
      <div class="kyc-method-header">
        <h1>{{ $t('register.step.step_3') }}</h1>
      </div>

      <div class="kyc-informations" v-for="(document, i) in returnKYCDocuments" :key="i">
        <div class="kyc-informations-header">
          <div class="kyc-informations-header-title">
            <img src="@/assets/svg/v2/registerProcess/document.svg" alt="KYC" class="themed-img"/>
            <h2>{{ returnDocumentTypeLabel(document.type) }}</h2>
          </div>
          <div class="kyc-informations-header-state">
            <p v-if="document.status === 'VALIDATED'" class="verified">{{ $t('register.step_3.send_at') }} {{ returnDate(document.send_at)}}</p>
            <p v-else-if="document.status === 'REFUSED'" class="refused">{{ $t('register.step_3.refused') }}</p>
            <p v-else-if="document.status === 'VALIDATION_ASKED'" class="empty">{{ $t('register.step_3.verification_in_progress') }}</p>
            <p v-else class="empty">{{ $t('settings.empty') }}</p>

            <img v-if="document.status === 'VALIDATED'" src="@/assets/svg/v2/registerProcess/complete.svg" alt="KYC complete" />
            <img v-else-if="document.status === 'REFUSED'" src="@/assets/svg/v2/registerProcess/refusedDocument.svg" alt="KYC refused" class="themed-img"/>
            <img v-else src="@/assets/svg/v2/registerProcess/awaitConfirmation.svg" alt="KYC empty" />
          </div>
        </div>

        <div v-if="document.files_count" class="document-send-informations">
            <p v-if="document.files_count === 1">{{ $t('register.step_3.document_count') }} {{ document.files_count }}</p>
            <p v-else>{{ $t('register.step_3.documents_count') }} {{ document.files_count }}</p>
        </div>

        <div v-if="document.reason" class="document-refused-informations">
            <p>{{ $t('register.step_3.refused_description') }} <span>{{ document.reason }}</span></p>
        </div>
      </div>

      <div @click.stop="redirect('/register')" v-if="isAccountTypeSelected < 4" class="registration-progress">
            <div>
                <div class="registration-progress-explain">
                    {{ $t('register.end_register') }}
                    <img src="@/assets/svg/v2/arrowToRight.svg" alt="Right arrow" class="themed-img"/>
                </div>
                <div>
                    {{ $t('register.verify_account') }}
                </div>
                </div>
                <div>
                <img v-if="isAccountTypeSelected === 1" src="@/assets/svg/v2/accountStep/2.svg" class="themed-img" alt="Confirm email & phone" />
                <img v-if="isAccountTypeSelected === 2" src="@/assets/svg/v2/accountStep/3.svg" class="themed-img" alt="KYC" />
                <img v-if="isAccountTypeSelected === 3" src="@/assets/svg/v2/accountStep/4.svg" class="themed-img" alt="Banking informations" />
            </div>
        </div>
    </div>
  </template>
  
  <script>
  import { mapActions, mapGetters } from 'vuex';
  import m from 'moment';
  import { find } from 'lodash';
  
  export default {
    components: {},
    data() {
      return {};
    },
    computed: {
      ...mapGetters({
        getUserDetails: 'getUserDetails',
      }),
      returnKYCDocuments() {
        if (this.getUserDetails?.identity.kyc) {

            const findVerifiedDocument = find(this.getUserDetails.identity.kyc, { status: 'VALIDATED' });
            if (findVerifiedDocument) {
                this.authVerification();
            }
            return this.getUserDetails.identity.kyc;
        }

        return [];
      },
      isAccountTypeSelected() {
      return this.$store.state.auth.register_step - 1;
    }
    },
    methods: {
        ...mapActions({
            authVerification: 'authverification'
        }),
        returnDate(date) {
          return m(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY HH:mm');
        },
        returnDocumentTypeLabel(type) {
            let label;

            if (type === 'IDENTITY_PROOF') {
                label = this.$t('register.step_3.IDENTITY_PROOF');
            } else if (type === 'REGISTRATION_PROOF') {
                label = this.$t('register.step_3.REGISTRATION_PROOF');
            } else if (type === 'ARTICLES_OF_ASSOCIATION') {
                label = this.$t('register.step_3.ARTICLES_OF_ASSOCIATION');
            } else if (type === 'SHAREHOLDER_DECLARATION') {
                label = this.$t('register.step_3.SHAREHOLDER_DECLARATION');
            } else {
                label = this.$t('register.step_3.ADDRESS_PROOF');
            }

            return label;
        },
        redirect(path) {
        this.$router.push(path);
        },
    },
  };
  </script>
      
<style lang="scss">
@import '@/assets/styles/root.scss';
  
.kyc-method-header, .kyc-informations-header, .kyc-informations-header-title, .kyc-informations-header-state {
    display: flex;
}

.kyc-informations {
    border-radius: 12px;
    border: 1px solid var(--border);
    background: var(--background-color-2);
    width: 100%;
    margin-bottom: 20px;
    padding-bottom: 20px;
    padding: 20px;

    .kyc-informations-header {
        justify-content: space-between;
        
        .kyc-informations-header-title {
            width: 50%;
            
            h2 {
                color: var(--text-color);
                font-size: 18px;
                font-weight: 600;
                margin-left: 12px;
            }
        }
        
        .kyc-informations-header-state {
            width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            
            img {
                margin-left: 8px;
            }
        }
    }

    .document-send-informations, .document-refused-informations {
        color: var(--subtitle-2-text-color);
        margin-top: 10px;

        p span {
            color: var(--text-color);
        }
    }
}

.verified, .empty {
    color: var(--buysuccess-color);
    font-size: 14px;
    font-weight: 500;
}

.empty {
    color: $warning-color;
}

.refused {
    color: $alert-color;
}
    
.kyc-method-history {
    margin-left: 53px!important;
    width: 100%;
    max-width: 750px;
    min-width: 600px;

    .kyc-method-header {
        justify-content: space-between;
        margin-bottom: 18px;

        h1 {
            font-size: 24px;
            font-weight: 600;
            padding: 0;
        }

        button {
            cursor: not-allowed;
            border-radius: 8px;
            border: 1px solid var(--border);
            background: var(--background-color-2);
            color: var(--subtitle-text-color);
            font-size: 15px;
            font-weight: 500;
            height: 36px;
            width: 143px;
        }
    }
}

.registration-progress {
    display: flex;
    align-items: center;
    background-color: $primary-color;
    border-radius: 8px;
    padding: 10px;
    max-width: 280px;
    cursor: pointer;
    color: var(--text-color);

    div {

        .registration-progress-explain {
            display: flex;
            align-items: center;
            margin-bottom: 7px;
            margin-top: 2px;
            margin-right: 10px;
        }

        div:nth-child(2) {
            font-size: 14px;
            color: var(--subtitle-text-color);
        }
    }
}

@media screen and (max-width: 500px) {
    .kyc-method-history {
        display: flex;
        flex-direction: column;
        align-items: center;
        min-width: 300px!important;
        margin-left: 0!important;
        color: var(--text-color);
    }
    .kyc-informations {
        margin-right: 0;
        width: 320px;
        padding: 20px 10px;

        .kyc-informations-header {
            flex-direction: column;
            align-items: center;

            .kyc-informations-header-state {
                margin-top: 15px;
            }

            div {
                width: auto!important;
            }
        }
    }

    .registration-progress{
        margin-bottom: 30px;
    }
}
</style>