<template>
  <div class="payment">
    <div class="payment-sources">
      <div class="payment-sources-selection">
        <img src="@/assets/svg/v2/dotActive.svg" alt="Selected banking card" />
        <p>{{ $t('subscriptions.banking_card') }}</p>
      </div>
      <div class="payment-sources-accepted">
        <img
          v-if="card.card_type === 'visa_mastercard' || !card.card_type"
          src="@/assets/svg/v2/subscriptions/visa.svg"
          alt="Visa"
        />
        <img
          v-if="card.card_type === 'visa_mastercard' || !card.card_type"
          src="@/assets/svg/v2/subscriptions/mastercard.svg"
          alt="Mastercard"
        />
        <img
          v-if="card.card_type === 'amex' || !card.card_type"
          src="@/assets/svg/v2/subscriptions/ae.svg"
          alt="AE"
        />
      </div>
    </div>

    <form>
      <el-input
        class="form-input"
        :class="{ errorInput: cardRegex.card_number }"
        :placeholder="$t('subscriptions.card_number')"
        type="text"
        minlength="15"
        maxlength="20"
        v-model="card.card_number"
        :suffix-icon="Lock"
      />
      <el-input
        class="form-input"
        :class="{ errorInput: cardRegex.fullnames }"
        :placeholder="$t('subscriptions.card_fullnames')"
        type="text"
        minlength="5"
        maxlength="75"
        v-model="card.fullnames"
      />

      <div>
        <el-input
          class="form-input"
          clearable
          :class="{ errorInput: cardRegex.card_expiration_date }"
          :placeholder="$t('subscriptions.card_expiration')"
          type="text"
          minlength="5"
          maxlength="10"
          v-model="card.card_expiration_date"
        />
        <el-input
          class="form-input"
          :class="{ errorInput: cardRegex.card_cvx }"
          :placeholder="$t('subscriptions.card_code_security')"
          type="text"
          minlength="3"
          maxlength="3"
          v-model="card.card_cvx"
        />
      </div>

      <button @click="updateCard()" class="generic-btn card-update">
        {{ $t('commons.confirm') }}
      </button>
    </form>
  </div>
</template>

<script setup>
import { Lock } from '@element-plus/icons-vue'
</script>

<script>
import { mapActions } from 'vuex'

export default {
  props: {
    currentCard: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      card: {
        fullnames: '',
        card_number: '',
        card_expiration_date: '',
        card_cvx: '',
        card_type: '',
        card_id: null,
      },
      cardRegex: {
        fullnames: false,
        card_number: false,
        card_expiration_date: false,
        card_cvx: false,
      },
      cardType: [
        { type: 'visa_mastercard', prfx: ['4'], fees: 2 },
        {
          type: 'visa_mastercard',
          prfx: ['51', '52', '53', '54', '55'],
          fees: 2,
        },
        { type: 'amex', prfx: ['34', '37'], fees: 3 },
      ],

      selectedCardType: null,
    }
  },
  watch: {
    'card.card_number'() {
      let card = this.card.card_number
      const carteNumbers = card.replace(/\D/g, '')

      for (let i = 0; i < this.cardType.length; i++) {
        for (const prf of this.cardType[i].prfx) {
          if (carteNumbers.startsWith(prf)) {
            if (this.cardType[i].type === 'visa_mastercard') {
              this.card.card_type = 'visa_mastercard'
              this.selectedCardType = this.cardType[i]
            } else if (this.cardType[i].type === 'amex') {
              this.card.card_type = 'amex'
              this.selectedCardType = this.cardType[i]
            }
          }
        }
      }
    },
    'card.card_expiration_date': function () {
      if (this.card.card_expiration_date) {
        let slashCount = 0
        for (var i = 0; i < this.card.card_expiration_date.length; i++) {
          if (this.card.card_expiration_date[i] === '/') {
            slashCount++
          }
        }

        if (slashCount > 1) {
          this.card.card_expiration_date = ''
        }

        if (this.card.card_expiration_date.length === 2) {
          this.card.card_expiration_date += '/'
        }
      }
    },
  },
  methods: {
    ...mapActions({
      updateCardInformations: 'card',
    }),
    async updateCard() {
      this.card.card_id = this.currentCard.card_id
      const payload = {
        card: this.card,
      }

      await this.updateCardInformations(payload).then(() => {
        this.$emit('close')
        this.card = {
          fullnames: '',
          card_number: '',
          card_expiration_date: '',
          card_cvx: '',
          card_type: '',
          card_id: null,
        }
      })
    },
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/root.scss';

.payment-sources,
.payment-sources-selection {
  display: flex;
}

.payment {
  background: var(--background-color);
  overflow: hidden;

  .payment-sources {
    align-items: center;
    justify-content: space-between;
    padding: 15px 25px;

    .payment-sources-selection {
      align-items: center;
      cursor: pointer;

      p {
        color: var(--text-color);
        font-size: 16px;
        font-weight: 500;
        margin-left: 8px;
      }
    }

    .payment-sources-accepted {
      display: flex;
      justify-content: flex-end;
      min-width: 130px;

      img {
        margin-left: 8px;
      }
    }
  }

  form {
    flex-direction: column;
    padding: 23px;
    background: var(--background-color-2);
    border-top: 1px solid var(--border);

    .form-input {
      border-radius: 5px;
      border: 1px solid var(--border);
      background: var(--background-color-2);
      margin-bottom: 16px;
      padding-left: 15px;
    }

    div {
      .form-input:nth-child(1) {
        margin-right: 20px;
      }
    }

    .card-update {
      width: 125px;
      height: 47px;
      margin: auto;
    }
  }
}
</style>
