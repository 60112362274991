<template>
  <div v-if="getUserDetails" class="account-details">
    <div class="account-details-menu">
      <h3>{{ $t('settings.title_menu') }}</h3>

      <button
        class="menu-my-account"
        :class="{ activeMenu: menuSelected === 0 }"
        @click="menuSelected = 0"
      >
        <img
          v-if="menuSelected === 0"
          src="@/assets/svg/v2/settings/accountActive.svg"
          class="themed-img"
          alt="My account active"
        />
        <img
          v-else
          src="@/assets/svg/v2/settings/accountActive.svg"
          class="themed-img"
          alt="My account"
        />
        <p>{{ $t('settings.account') }}</p>
      </button>

      <button
        class="menu-buy-sell"
        :class="{ activeMenu: menuSelected === 1 }"
        @click="menuSelected = 1"
      >
        <img
          v-if="menuSelected === 1"
          src="@/assets/svg/v2/settings/buySellActive.svg"
          class="themed-img"
          alt="Buy & Sell active"
        />
        <img
          v-else
          src="@/assets/svg/v2/settings/buySellActive.svg"
          class="themed-img"
          alt="Buy & Sell"
        />
        <p>{{ $t('settings.buy_sell') }}</p>
      </button>

      <button
        class="menu-bank-informations"
        :class="{ activeMenu: menuSelected === 2 }"
        @click="menuSelected = 2"
      >
        <img
          v-if="menuSelected === 2"
          src="@/assets/svg/v2/settings/bankActive.svg"
          class="themed-img"
          alt="Bank active"
        />
        <img
          v-else
          src="@/assets/svg/v2/settings/bankActive.svg"
          class="themed-img"
          alt="Banking informations"
        />
        <p>{{ $t('settings.bank_informations') }}</p>
      </button>

      <button
        class="menu-security"
        :class="{ activeMenu: menuSelected === 3 }"
        @click="menuSelected = 3"
      >
        <img
          v-if="menuSelected === 3"
          src="@/assets/svg/v2/settings/securityActive.svg"
          class="themed-img"
          alt="Security active"
        />
        <img
          v-else
          src="@/assets/svg/v2/settings/securityActive.svg"
          class="themed-img"
          alt="Account security"
        />
        <p>{{ $t('settings.security') }}</p>
      </button>

      <button
        class="preferences"
        :class="{ activeMenu: menuSelected === 8 }"
        @click="menuSelected = 8"
      >
        <img
          v-if="menuSelected === 8"
          src="@/assets/svg/v2/settings/notificationsActive.svg"
          class="themed-img"
          alt="Notifications menu active"
        />
        <img
          v-else
          src="@/assets/svg/v2/settings/notificationsActive.svg"
          class="themed-img"
          alt="Notifications menu"
        />
        <p>{{ $t('settings.preferences_mails') }}</p>
      </button>

      <button
        class="menu-kyc"
        :class="{ activeMenu: menuSelected === 7 }"
        @click="menuSelected = 7"
      >
        <img
          v-if="menuSelected === 7"
          src="@/assets/svg/v2/registerProcess/documentActive.svg"
          class="themed-img"
          alt="Account KYC active"
        />
        <img
          v-else
          src="@/assets/svg/v2/registerProcess/documentActive.svg"
          class="themed-img"
          alt="Account KYC"
        />
        <p>{{ $t('register.step.step_3') }}</p>
      </button>

      <h3 class="menu-title-subscriptions">
        {{ $t('settings.title_subscriptions') }}
      </h3>

      <button
        class="menu-my-subscriptions"
        :class="{ activeMenu: menuSelected === 4 }"
        @click="menuSelected = 4"
      >
        <img
          v-if="menuSelected === 4"
          src="@/assets/svg/v2/settings/mySubscriptionsActive.svg"
          class="themed-img"
          alt="Subscriptions active"
        />
        <img
          v-else
          src="@/assets/svg/v2/settings/mySubscriptionsActive.svg"
          class="themed-img"
          alt="My subscriptions"
        />
        <p>{{ $t('settings.my_subscriptions') }}</p>
      </button>

      <button
        class="menu-payment-method"
        :class="{ activeMenu: menuSelected === 5 }"
        @click="menuSelected = 5"
      >
        <img
          v-if="menuSelected === 5"
          src="@/assets/svg/v2/settings/paymentMethodActive.svg"
          class="themed-img"
          alt="Payment method active"
        />
        <img
          v-else
          src="@/assets/svg/v2/settings/paymentMethodActive.svg"
          class="themed-img"
          alt="Payment method"
        />
        <p>{{ $t('settings.payment_method') }}</p>
      </button>

      <button
        class="menu-invoice-history"
        :class="{ activeMenu: menuSelected === 6 }"
        @click="menuSelected = 6"
      >
        <img
          v-if="menuSelected === 6"
          src="@/assets/svg/v2/settings/invoiceHistoryActive.svg"
          class="themed-img"
          alt="Invoice history active"
        />
        <img
          v-else
          src="@/assets/svg/v2/settings/invoiceHistoryActive.svg"
          class="themed-img"
          alt="Invoice history"
        />
        <p>{{ $t('settings.invoice_history') }}</p>
      </button>
    </div>

    <div class="account-details-body" v-if="menuSelected === 0">
      <h1>{{ $t('settings.account') }}</h1>

      <div class="personnal-informations">
        <div class="personnal-informations-header">
          <img
            src="@/assets/svg/v2/settings/personnalInformations.svg"
            class="themed-img"
            alt="Personnal informations"
          />
          <h2>{{ $t('settings.personnal_informations') }}</h2>
        </div>

        <div class="labels">
          <p class="label">{{ $t('settings.civility') }}</p>
          <p>{{ getUserCivility(getUserDetails.identity.civility) }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.firstname') }}</p>
          <p>{{ getUserDetails.identity.firstname.toUpperCase() }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.lastname') }}</p>
          <p>{{ getUserDetails.identity.lastname.toUpperCase() }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('ratings.pseudo') }}</p>
          <div class="label-div">
            <p>{{ getUserDetails.identity.pseudo || '-' }}</p>
            <button @click="drawerUpdateGlobalInformations = true" class="btn-update">
              {{ $t('settings.update') }}
            </button>
          </div>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.birthdate') }}</p>
          <p>{{ convertBirthdate }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.nationality') }}</p>
          <p>{{ getUserNationality(getUserDetails.identity.nationality) }}</p>
        </div>
        <div v-if="getUserDetails.identity.birth_city" class="labels">
          <p class="label">{{ $t('settings.birth_city') }}</p>
          <p>{{ getUserDetails.identity.birth_city }}</p>
        </div>
        <div v-if="getUserDetails.identity.birth_country" class="labels">
          <p class="label">{{ $t('settings.birth_country') }}</p>
          <p>{{ getUserNationality(getUserDetails.identity.birth_country) }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.email') }}</p>
          <div class="label-div">
            <p>{{ getUserDetails.address.email }}</p>
            <button @click="updateContactDialog('email')"  class="btn-update">
              {{ $t('settings.update') }}
            </button>
          </div>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.phone') }}</p>
          <div class="label-div">
            <p>{{ getUserDetails.address.phone }}</p>
            <button @click="updateContactDialog('phone')" class="btn-update">
              {{ $t('settings.update') }}
            </button>
          </div>
        </div>
      </div>

      <div
        v-if="getUserDetails.address.account_type === 1"
        class="personnal-informations"
      >
        <div class="personnal-informations-header">
          <img src="@/assets/svg/v2/pro.svg" alt="Business informations" class="themed-img"/>
          <h2>{{ $t('settings.business') }}</h2>
        </div>

        <div class="labels">
          <p class="label">{{ $t('register.company_name') }}</p>
          <p>{{ getUserDetails.address.organization.name.toUpperCase() }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('register.company_type') }}</p>
          <p>
            {{
              getBusinessType(
                getUserDetails.address.organization.organization_type
              )
            }}
          </p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('register.company_siret') }}</p>
          <p>{{ getUserDetails.address.organization.siret.toUpperCase() }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('register.company_vat') }}</p>
          <p>{{ getUserDetails.address.organization.vat.toUpperCase() }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('register.step_1.business_status') }}</p>
          <p>{{ getUserDetails.address.organization.status.toUpperCase() }}</p>
        </div>
        <div v-if="getUserDetails.address.organization.region" class="labels">
          <p class="label">{{ $t('register.region') }}</p>
          <p>{{ getUserDetails.address.organization.region }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('register.company_country') }}</p>
          <p>
            {{
              getUserNationality(getUserDetails.address.organization.country)
            }}
          </p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('register.step_1.business_type') }}</p>
          <p>
            {{
              getBusinessCategory(
                getUserDetails.address.organization.business_type
              )
            }}
          </p>
        </div>
      </div>

      <el-drawer
        v-model="drawerUpdateContactStep1"
        :title="
          updateType === 'email'
            ? $t('settings.update_dialog.title_email')
            : $t('settings.update_dialog.title_phone')
        "
        :size="drawerSize"
        @close="updateContactDialog(null)"
      >
        <updateContact
          :updateType="updateType"
          :form="getUserDetails"
          @values="setProvisionalUpdateContact"
        />
      </el-drawer>

      <el-drawer
        v-model="drawerUpdateDeliveryAddress"
        :title="$t('settings.update_dialog.title_delivery')"
        :size="drawerSize"
        @close="updateDeliveryDialog(null)"
      >
        <updateDeliveryAddress
          :delivery="updateDelivery"
          :form="getUserDetails"
          @close="updateDeliveryDialog(null)"
        />
      </el-drawer>

      <div class="billing-address-delivery" v-if="menuSelected === 0">
        <div class="billing-address-delivery-header">
          <img
            src="@/assets/svg/v2/settings/billing.svg"
            class="themed-img"
            alt="Billing address"
          />
          <h2>{{ $t('settings.billing_address') }}</h2>
        </div>

        <div v-if="getUserDetails.address.account_type === 0">
          <div class="labels">
            <p class="label">{{ $t('settings.address') }}</p>
            <p>{{ getUserDetails.address.address.toUpperCase() }}</p>
          </div>
          <div class="labels">
            <p class="label">{{ $t('settings.zipcode') }}</p>
            <p>{{ getUserDetails.address.zip }}</p>
          </div>
          <div class="labels">
            <p class="label">{{ $t('settings.city') }}</p>
            <p>{{ getUserDetails.address.city.toUpperCase() }}</p>
          </div>
          <div v-if="getUserDetails.address.region" class="labels">
            <p class="label">{{ $t('settings.region') }}</p>
            <p>{{ getUserDetails.address.region.toUpperCase() }}</p>
          </div>
          <div class="labels">
            <p class="label">{{ $t('settings.country') }}</p>
            <p>{{ getUserDetails.address.country.toUpperCase() }}</p>
          </div>
        </div>
        <div v-else>
          <div class="labels">
            <p class="label">{{ $t('settings.address') }}</p>
            <p>
              {{ getUserDetails.address.organization.address.toUpperCase() }}
            </p>
          </div>
          <div class="labels">
            <p class="label">{{ $t('settings.zipcode') }}</p>
            <p>{{ getUserDetails.address.organization.zip }}</p>
          </div>
          <div class="labels">
            <p class="label">{{ $t('settings.city') }}</p>
            <p>{{ getUserDetails.address.organization.city.toUpperCase() }}</p>
          </div>
          <div class="labels">
            <p class="label">{{ $t('settings.country') }}</p>
            <p>
              {{
                getUserNationality(
                  getUserDetails.address.organization.country
                ).toUpperCase()
              }}
            </p>
          </div>
        </div>
      </div>

      <div
        class="address-delivery"
        v-for="(multipleAddress, i) in getUserDetails.address
          .delivery_multiple_address"
        :key="i"
      >
        <div class="address-delivery-header">
          <img
            src="@/assets/svg/v2/settings/delivery.svg"
            class="themed-img"
            alt="Delivery address"
          />
          <h2>{{ $t('settings.delivery_address') }} ({{ i + 1 }})</h2>
        </div>

        <div v-if="multipleAddress.is_relay" class="labels">
          <p class="label">{{ $t('settings.name') }}</p>
          <p>{{ multipleAddress.name.toUpperCase() }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.address') }}</p>
          <p>{{ multipleAddress.address.toUpperCase() }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.zipcode') }}</p>
          <p>{{ multipleAddress.zip.toUpperCase() }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.city') }}</p>
          <p>{{ multipleAddress.city.toUpperCase() }}</p>
        </div>
        <div v-if="multipleAddress.region" class="labels">
          <p class="label">{{ $t('settings.region') }}</p>
          <p>{{ multipleAddress.region.toUpperCase() }}</p>
        </div>
        <div class="labels">
          <p class="label">{{ $t('settings.country') }}</p>
          <p>{{ getUserNationality(multipleAddress.country).toUpperCase() }}</p>
        </div>

        <div class="delivery-delete">
          <el-checkbox
            v-if="!multipleAddress.is_reference && !multipleAddress.is_relay"
            v-model="multipleAddress.is_reference"
            @click="isReferenceAddress(i, multipleAddress.is_reference)"
            class="checkbox-is-reference"
            :label="$t('settings.delivery_choice_reference')"
            :size="size"
          />
          <p v-else-if="!multipleAddress.is_relay">
            {{ $t('settings.delivery_is_reference') }}
          </p>
          <p
            v-else-if="
              multipleAddress.is_relay && multipleAddress.is_relay_reference
            "
          >
            {{ $t('settings.relay_ups') }}
          </p>
          <span v-else>{{ $t('settings.relay_ups') }}</span>
          <button
            class="btn-action action-light"
            v-if="!multipleAddress.is_reference"
            @click="deleteDeliveryAddress(i)"
          >
            {{ $t('settings.delivery_delete') }}
          </button>
        </div>
      </div>

      <div class="add-adress-container">
        <button
          v-if="
            !getUserDetails.address.delivery_multiple_address.length < 5 &&
            menuSelected === 0
          "
          @click="updateDelivery = true"
          class="update-delivery-address-btn btn-action action-light"
        >
          {{ $t('settings.other_delivery_address') }}
        </button>
      </div>
    </div>

    <div class="account-details-body" v-if="menuSelected === 1">
      <h1>{{ $t('settings.buy_sell') }}</h1>

      <buySellOptions />
    </div>

    <div class="account-details-body" v-if="menuSelected === 2">
      <h1>{{ $t('settings.bank_informations') }}</h1>

      <div class="banking-informations">
        <div class="banking-informations-header">
          <div class="banking-informations-header-title">
            <img
              src="@/assets/svg/v2/settings/bank.svg"
              class="themed-img"
              alt="Bank informations"
            />
            <h2>RIB</h2>
          </div>
          <div class="banking-informations-header-state">
            <p v-if="getUserDetails.banking.iban" class="verified">
              {{ $t('settings.verified') }}
            </p>
            <img
              v-if="getUserDetails.banking.iban"
              src="@/assets/svg/v2/registerProcess/complete.svg"
              alt="Bank informations complete"
            />

            <p v-if="!getUserDetails.banking.iban" class="empty">
              {{ $t('settings.empty') }}
            </p>
            <img
              v-if="!getUserDetails.banking.iban"
              src="@/assets/svg/v2/registerProcess/awaitConfirmation.svg"
              alt="Bank informations empty"
            />
          </div>
        </div>
        <div class="banking-form">
          <div class="identity-informations">
            <h4>{{ $t('settings.fullnames') }}</h4>
            <el-input
              class="input"
              type="text"
              :size="size"
              :value="returnFullnames"
              disabled
            />
          </div>
          <div class="inputs-wrap-tel">
            <h4>{{ $t('register.step_4.iban') }}</h4>

            <div class="iban-info">
              <el-select
                class="select-input input-select"
                :size="size"
                :disabled="disabledBankingInputs"
                :class="{ errorInput: regex.country }"
                v-model="banking_informations.country"
                :effect="darkmode ? 'dark' : ''"
              >
                <el-option label="France" value="FR"></el-option>
                <el-option label="Belgique" value="BE"></el-option>
                <el-option label="Suisse" value="CH"></el-option>
                <el-option label="Luxembourg" value="LU"></el-option>
                <el-option label="Allemagne" value="DE"></el-option>
                <el-option label="Espagne" value="ES"></el-option>
                <el-option label="Italie" value="IT"></el-option>
                <el-option label="Portugal" value="PT"></el-option>
                <el-option label="Royaume-Uni" value="GB"></el-option>
                <el-option label="Autre" value="OTHER"></el-option>
              </el-select>

              <el-input
                class="input"
                type="text"
                minlength="18"
                maxlength="35"
                :size="size"
                :disabled="disabledBankingInputs"
                v-model="banking_informations.iban"
                clearable
                :class="{ errorInput: regex.iban }"
                :placeholder="$t('register.step_4.iban_placeholder')"
              />
            </div>
          </div>
          <div class="inputs-wrap-tel">
            <h4>{{ $t('register.step_4.bic') }}</h4>
            <el-input
              class="input"
              type="text"
              minlength="7"
              maxlength="20"
              :size="size"
              :disabled="disabledBankingInputs"
              v-model="banking_informations.bic"
              clearable
              :class="{ errorInput: regex.bic }"
              :placeholder="$t('register.step_4.bic_placeholder')"
            />
          </div>
          <div class="inputs-wrap-tel">
            <h4>{{ $t('register.step_4.region') }}</h4>
            <el-input
              class="input"
              type="text"
              minlength="2"
              maxlength="50"
              :size="size"
              :disabled="disabledBankingInputs"
              v-model="banking_informations.region"
              clearable
              :class="{ errorInput: regex.region }"
              :placeholder="$t('register.step_4.region_placeholder')"
            />
          </div>
          <!-- <div v-if="!getUserDetails?.banking?.iban">
            <h4></h4>
            <button @click="checkAllInputs()" class="generic-btn">{{ $t('settings.update_dialog.register') }}</button>
          </div> -->

          <div class="iban-update">
            <p>{{ $t('settings.created_at') }} {{ returnCreatedAt }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="account-details-body" v-if="menuSelected === 3">
      <h1>{{ $t('settings.security') }}</h1>

      <div class="support-2fa">
        <div class="support-2fa-header">
          <img src="@/assets/svg/v2/settings/keys.svg" alt="2FA" class="themed-img"/>
          <h2>{{ $t('settings.2fa_title') }}</h2>
        </div>

        <div class="support-2fa-body">
          <p>{{ $t('settings.2fa_subtitle') }}</p>

          <div class="support-2fa-email">
            <p :class="{ active2FA: email2FAActive }">
              {{ $t('settings.2fa_email') }}
            </p>

            <el-switch
              v-model="email2FAActive"
              style="
                --el-switch-on-color: #2667ff;
                --el-switch-off-color: #555555;
              "
              disabled
            />
          </div>
          <div class="support-2fa-phone">
            <p :class="{ active2FA: phone2FAActive }">
              {{ $t('settings.2fa_phone') }}
            </p>

            <el-switch
              v-model="phone2FAActive"
              style="
                --el-switch-on-color: #2667ff;
                --el-switch-off-color: #555555;
              "
              disabled
            />
          </div>
        </div>
      </div>

      <div class="login-history">
        <div class="login-history-header">
          <img src="@/assets/svg/v2/settings/hour.svg" alt="Login history" class="themed-img"/>
          <h2>{{ $t('settings.login_history_title') }}</h2>
        </div>

        <div class="login-history-body">
          <p>{{ $t('settings.login_history_subtitle') }}</p>

          <div
            v-for="(history, n) in getUserDetails?.login_history"
            :key="n"
            class="login-history-list"
          >
            <div class="login-history-infos">
              <div>
                {{ historyDate(history.login_at) }}
              </div>
              <p>
                {{ historyCountry(history.country) }}
                <span>•</span>
                {{ history.ip?.replace('::ffff:', '') }}
              </p>
            </div>
            <el-divider
              v-if="n < getUserDetails?.login_history.length - 1"
              class="divider"
            />
          </div>
        </div>
      </div>
    </div>

    <kyc v-if="menuSelected === 7"></kyc>

    <MySubscriptions v-if="menuSelected === 4"></MySubscriptions>
    <paymentMethod v-if="menuSelected === 5"></paymentMethod>
    <invoicesHistory v-if="menuSelected === 6"></invoicesHistory>
    <preferences v-if="menuSelected === 8"></preferences>

    <el-drawer
      v-model="drawerUpdateContactStep2"
      :title="$t('settings.confirm.subtitle_email')"
      :size="drawerSize"
      @close="drawerUpdateContactStep2 = false"
    >
      <codeconfirm_V2
        :values="currentChange"
        @close="drawerUpdateContactStep2 = false"
      />
    </el-drawer>

    <el-drawer
      v-model="drawerUpdateGlobalInformations"
      :title="$t('settings.update_account')"
      :size="drawerSize"
      @close="drawerUpdateGlobalInformations = false"
    >
      <updateAccountInformations :accountDetails="getUserDetails" @close="drawerUpdateGlobalInformations = false" />
    </el-drawer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { find } from 'lodash'
import m from 'moment'
import { inject } from "vue"
import { ElMessage } from 'element-plus'
import updateContact from '@/components/connected/settings/updateContact.vue'
import buySellOptions from '@/components/connected/settings/buySellOptions.vue'
import updateDeliveryAddress from '@/components/connected/settings/updateDeliveryAddress.vue'
import codeconfirm_V2 from '@/components/modules/dialogs/codeConfirmation_V2.vue'
import MySubscriptions from '@/components/connected/settings/mySubscriptions.vue'
import invoicesHistory from '@/components/connected/settings/invoicesHistory.vue'
import kyc from '@/components/connected/settings/kyc.vue'
import paymentMethod from '@/components/connected/settings/paymentMethod.vue'
import preferences from '@/components/connected/settings/preferences.vue'
import updateAccountInformations from '@/components/connected/updateAccountInformations.vue'

export default {
  components: {
    updateContact,
    updateDeliveryAddress,
    codeconfirm_V2,
    MySubscriptions,
    kyc,
    invoicesHistory,
    paymentMethod,
    buySellOptions,
    preferences,
    updateAccountInformations
  },
  data() {
    return {
      darkmode: inject("darkmode"),
      size: 'large',
      disabledBankingInputs: true,

      drawerUpdateContactStep1: false,
      drawerUpdateContactStep2: false,
      drawerUpdateDeliveryAddress: false,
      drawerUpdateGlobalInformations: false,

      menuSelected: 0,
      updateType: null,
      updateDelivery: null,
      currentChange: null,

      banking_informations: {
        fullnames: null,
        region: null,
        country: null,
        iban: null,
        bic: null,
      },

      regex: {
        region: null,
        country: null,
        bic: null,
        iban: null,
      },

      email2FAActive: true,
      phone2FAActive: false,
    }
  },
  mounted() {
    if (this.getUserDetails) {
      this.banking_informations = this.getUserDetails.banking

      if (this.getUserDetails.banking.iban) {
        this.disabledBankingInputs = true
      }
    } else {
      this.getClient()
    }

    if (this.$route.query.subscription) {
      this.menuSelected = 4
    } else if (this.$route.query.invoices) {
      this.menuSelected = 6
    }
  },
  watch: {
    email2FAActive() {
      if (this.email2FAActive) {
        this.phone2FAActive = false
      }
    },
    phone2FAActive() {
      if (this.phone2FAActive) {
        this.email2FAActive = false
      }
    },
    'banking_informations.iban': function () {
      const regex_iban = /^[0-9a-zA-Z]{1,35}$/
      if (!regex_iban.test(this.banking_informations.iban)) {
        this.banking_informations.iban = null
      }
    },
    'banking_informations.bic': function () {
      const regex_bic = /^[0-9a-zA-Z]{1,11}$/
      if (!regex_bic.test(this.banking_informations.bic)) {
        this.banking_informations.bic = null
      }
    },
    returnGetClient() {
      if (this.getUserDetails) {
        if (this.getUserDetails.type_2fa === 0) {
          this.email2FAActive = false
          this.phone2FAActive = false
        } else if (this.getUserDetails.type_2fa === 1) {
          this.email2FAActive = true
          this.phone2FAActive = false
        } else if (this.getUserDetails.type_2fa === 2) {
          this.email2FAActive = false
          this.phone2FAActive = true
        }
      } else {
        this.getClient()
      }
    },
    updateType() {
      if (this.updateType) {
        this.drawerUpdateContactStep1 = true
      } else {
        this.drawerUpdateContactStep1 = false
      }
    },
    updateDelivery() {
      if (this.updateDelivery) {
        this.drawerUpdateDeliveryAddress = true
      } else {
        this.drawerUpdateDeliveryAddress = false
      }
    },
    drawerUpdateContactStep2() {
      if (this.drawerUpdateContactStep2) {
        this.drawerUpdateContactStep1 = false
      }
    },
  },
  computed: {
    ...mapGetters({
      getUserDetails: 'getUserDetails',
      getLists: 'getLists',
    }),
    convertBirthdate() {
      if (this.getUserDetails.identity.birthdate) {
        return m(this.getUserDetails.identity.birthdate).format('DD/MM/YYYY')
      }
      return '-'
    },
    returnFullnames() {
      return `${this.getUserDetails?.identity.lastname.toUpperCase()} ${this.getUserDetails?.identity.firstname.toUpperCase()}`
    },
    returnCreatedAt() {
      if (this.getUserDetails.banking.created_at) {
        return m(this.getUserDetails.banking.created_at).format(
          'DD/MM/YYYY HH:mm'
        )
      } else {
        return '-'
      }
    },
    returnGetClient() {
      return this.getUserDetails
    },
    country() {
      return this.getLists?.country
    },
    civility() {
      return this.getLists?.civility
    },
    drawerSize() {
      if (window.innerWidth < 500) {
        return '100%'
      }
      return '40%'
    },
  },
  methods: {
    ...mapActions({
      getClient: 'getAccountDetails',
      changeDeliveryAddress: 'changeDeliveryAddress',
      sendBankingInformations: 'sendBankingInformations',
    }),
    checkAllInputs() {
      this.checkIBAN()
      this.checkBIC()

      if (!this.banking_informations.country) {
        this.regex.country = true
      } else {
        this.regex.country = false
      }

      if (!this.banking_informations.region) {
        this.regex.region = true
      } else {
        this.regex.region = false
      }

      this.checkValues()
    },
    checkIBAN() {
      const regex_iban = /^[0-9a-zA-Z]{18,35}$/

      if (!regex_iban.test(this.banking_informations.iban)) {
        this.regex.iban = true
      } else {
        this.regex.iban = false
      }
    },
    checkBIC() {
      const regex_bic = /^[0-9a-zA-Z]{8,11}$/

      if (!regex_bic.test(this.banking_informations.bic)) {
        this.regex.bic = true
      } else {
        this.regex.bic = false
      }
    },
    async checkValues() {
      if (!this.regex.iban && !this.regex.bic) {
        await this.sendBankingInformations(this.banking_informations).then(
          async () => {
            this.getClient()
          }
        )
      } else {
        ElMessage({
          message: this.$t('commons.check_inputs_short'),
          type: 'warning',
          grouping: true,
        })
      }
    },
    updateContactDialog(type) {
      this.updateType = type
    },
    updateDeliveryDialog(state) {
      this.updateDelivery = state
    },
    historyDate(date) {
      return m(date).format('DD/MM/YYYY HH:mm')
    },
    historyCountry(country) {
      if (country) {
        return country
      }
      return 'N/A'
    },
    setProvisionalUpdateContact(values) {
      this.currentChange = values
      this.drawerUpdateContactStep2 = true
    },
    deleteDeliveryAddress(index) {
      const newAddressArray =
        this.getUserDetails.address.delivery_multiple_address.filter(
          (element, i) => i !== index
        )

      const payload = {
        address: newAddressArray,
      }

      this.changeDeliveryAddress(payload)
    },
    getUserNationality(country) {
      const findCountry = find(this.country, { alpha2: country })

      if (findCountry) {
        return findCountry.nom_en_gb
      }
      return 'N/A'
    },
    getUserCivility(civility) {
      const findCivility = find(this.civility, { value: civility })

      if (findCivility) {
        return findCivility.label
      }
      return 'N/A'
    },
    getBusinessCategory(category) {
      const findCategory = find(this.getLists.business_type, {
        value: category,
      })

      if (findCategory) {
        return findCategory.label
      }
      return 'N/A'
    },
    getBusinessType(type) {
      const findType = find(this.getLists.organization_type, { value: type })

      if (findType) {
        return findType.label
      }
      return 'N/A'
    },
    async isReferenceAddress(i, state) {
      this.getUserDetails.address.delivery_multiple_address.forEach((o) => {
        o.is_reference = false
      })

      this.getUserDetails.address.delivery_multiple_address[i].is_reference =
        !state
      const payload = {
        address: this.getUserDetails.address.delivery_multiple_address,
      }
      await this.changeDeliveryAddress(payload).then(() => {
        this.getClient()
      })
    },
    updateGlobalAccountDetails() {

    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/root.scss';

.label-div {
  display: flex;
}

.btn-update {
  border: none;
  border-bottom: 2px solid var(--text-color);
  background-color: transparent;
}

.btn-action {
  padding: 5px 8px;
  background: $primary-color !important;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 400;
  border: 0;
}

@media only screen and (max-width: 1000px) {
  .account-details-body {
    margin-left: 20px !important;
  }

  .labels {
    justify-content: space-between;
    margin-left: 20px !important;
    margin-right: 20px !important;
    .label {
      min-width: 0px !important;
    }
  }

  .account-details {
    margin-left: 20px;
    margin-right: 20px;
  }

  .account-details-menu {
    min-width: 0px !important;
    margin-top: 60px !important;

    button {
      width: 38px !important;

      p {
        display: none;
      }
    }

    h3 {
      display: none;
    }
  }
}

.account-details,
.account-details-menu,
.banking-informations-header,
.banking-informations-header-title,
.banking-informations-header-state {
  display: flex;
}

.account-details {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 50px;

  .account-details-menu {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 5px;
    min-width: 200px;

    h3 {
      color: var(--text-color);
      font-size: 18px;
      font-weight: 700;
      margin-bottom: 10px;
    }

    .menu-title-subscriptions {
      margin-top: 10px;
    }

    button {
      display: flex;
      align-items: center;
      background: transparent;
      border: 0;
      margin-bottom: 5px;
      color: var(--text-color);
      font-size: 15px;
      font-weight: 500;
      width: 221px;
      height: 36px;

      img {
        margin-right: 8px;
        margin-left: 10px;
      }
    }
  }

  .account-details-body {
    margin-left: 53px;
    width: 100%;
    max-width: 750px;

    h1 {
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 24px;
    }

    .personnal-informations,
    .billing-address-delivery,
    .address-delivery,
    .banking-informations {
      border-radius: 12px;
      border: 1px solid var(--border);
      background: var(--background-color-2);
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 20px;

      .personnal-informations-header,
      .billing-address-delivery-header,
      .address-delivery-header {
        display: flex;
        align-items: center;
        margin-bottom: 18px;
        margin-top: 20px;
        margin-left: 20px;

        h2 {
          color: var(--text-color);
          font-size: 18px;
          font-weight: 600;
        }

        img {
          margin-right: 12px;
        }
      }

      .labels {
        margin-left: 56px;
        display: flex;
        align-items: center;
        margin-top: 12px;

        p {
          color: var(--text-color);
          font-size: 16px;
          font-weight: 500;
        }

        .label {
          color: var(--subtitle-text-color);
          font-size: 16px;
          font-weight: 500;
          min-width: 250px;
        }

        button {
          margin-left: 10px;
        }
      }

      .delivery-delete {
        display: flex;
        justify-content: space-between;
        width: 100%;
        text-align: right;
        margin-top: 20px;
        margin-left: 20px;

        p {
          color: #67c23a;
        }

        .checkbox-is-reference {
          color: var(--text-color);
        }

        button {
          margin-right: 25px;
        }
      }
    }
    .add-adress-container {
      width: 100%;
      display: flex;
      justify-content: right;

      // .update-delivery-address-btn {
      //   margin: 0 0 0 auto;
      //   font-size: 15px;
      //   font-weight: 500;
      //   border: 0;
      //   text-align: right;
      // }
    }
  }
}

.banking-informations {
  padding: 20px;

  .banking-informations-header {
    justify-content: space-between;

    .banking-informations-header-title {
      width: 50%;

      h2 {
        color: var(--text-color);
        font-size: 18px;
        font-weight: 600;
        margin-left: 12px;
      }
    }

    .banking-informations-header-state {
      width: 50%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      img {
        margin-left: 8px;
      }
    }
  }

  .banking-form {
    margin-top: 24px;

    .identity-informations {
      width: 100%;
      display: flex;
      align-items: center;

      h4 {
        min-width: 170px;
        color: var(--subtitle-text-color);
        font-size: 16px;
        font-weight: 500;
      }
    }

    .iban-update {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid var(--border);
      margin-top: 29px;
      padding-top: 20px;

      p {
        color: var(--subtitle-2-text-color);
        font-size: 14px;
        font-weight: 500;
      }

      button {
        background: transparent;
        border: 0;
        color: var(--subtitle-text-color);
        font-size: 15px;
        font-weight: 500;
        margin: 0;
      }
    }

    div {
      display: flex;
      align-items: center;

      h4 {
        min-width: 170px;
        color: var(--subtitle-text-color);
        font-size: 16px;
        font-weight: 500;
      }

      .iban-info {
        width: 100%;
      }

      button {
        max-width: 300px;
      }
    }
  }

  button {
    margin: auto;
    margin-top: 25px;
  }
}

.input,
.input-select {
  border-radius: 5px;
  border: 1px solid var(--border);
  background: var(--background-color-2);
  margin-top: 12px;
}

.input-select {
  border-right: 0;
}

.verified,
.empty {
  color: var(--buysuccess-color);
  font-size: 14px;
  font-weight: 500;
}

.empty {
  color: $warning-color;
}

.support-2fa,
.login-history {
  border-radius: 12px;
  border: 1px solid var(--border);
  background: var(--background-color-2);

  .support-2fa-header,
  .login-history-header {
    display: flex;
    align-items: center;
    margin-left: 20px;
    margin-top: 20px;

    h2 {
      color: var(--text-color);
      font-size: 18px;
      font-weight: 600;
      margin-left: 12px;
    }
  }

  .support-2fa-body,
  .login-history-body {
    padding-bottom: 30px;

    p {
      color: var(--subtitle-text-color);
      font-size: 15px;
      font-weight: 500;
      margin-left: 56px;
      margin-top: 12px;
      margin-bottom: 35px;
      margin-right: 50px;
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-right: 25px;

      p {
        margin-bottom: 0;
        color: var(--subtitle-2-text-color);
        font-size: 16px;
        font-weight: 500;
      }
    }

    .login-history-list {
      display: flex;
      flex-direction: column;

      .login-history-infos {
        display: flex;
        justify-content: space-between;
        width: 80%;
        margin-left: 25px;
      }

      div,
      p {
        color: var(--subtitle-text-color);
        font-size: 14px;
        font-weight: 500;
      }

      p {
        margin-right: 0;
      }

      .divider {
        width: 80%;
        margin: 10px auto;
      }
    }
  }
}

.support-2fa {
  margin-bottom: 20px;
}

.active2FA {
  color: var(--text-color) !important;
  font-weight: 600 !important;
}

.activeMenu {
  border-radius: 8px;
  background: var(--background-color-3) !important;
  box-shadow: var(--box-shadow-8);
  color: var(--text-color) !important;
  font-size: 15px;
  font-weight: 600;
}

@media screen and (max-width: 500px) {
  .account-details {
    flex-wrap: wrap;
    margin: auto 10px;
    margin-top: 0;

    .account-details-menu {
      flex-direction: row;
      margin-left: 0;
      margin-top: 20px !important;
      margin-bottom: 20px;
      width: 100%;

      button {
        display: flex;
        justify-content: center;
        margin: 0;

        img {
          margin: 0;
        }
      }
    }
  }

  .account-details-body {
    margin-left: 0 !important;

    .personnal-informations,
    .billing-address-delivery,
    .address-delivery {
      .personnal-informations-header,
      .billing-address-delivery-header,
      .address-delivery-header {
        margin-left: 10px !important;
      }

      .labels {
        margin-left: 10px !important;
        margin-right: 10px !important;

        p {
          font-size: 14px !important;
        }
      }
    }
  }

  .banking-informations {
    width: auto !important;
    padding: 20px 10px;
  }

  .banking-form {
    .identity-informations,
    .inputs-wrap-tel {
      flex-wrap: wrap;
      margin-top: 10px;
    }
  }

  .login-history {
    margin-bottom: 20px;

    .login-history-header {
      margin-left: 10px !important;
    }
  }

  .login-history-body {
    p {
      margin: 10px !important;
    }
  }

  .login-history-list {
    margin-right: 0 !important;

    .login-history-infos {
      width: 90% !important;
      margin: auto 0px !important;
    }
  }

  .update-delivery {
    width: 98%;
    max-width: 400px;
    height: 480px;
  }

  .update-contact {
    width: 98%;
  }

  .delivery-delete {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-left: 10px !important;
  }

  .add-adress-container {
    margin-bottom: 20px;
  }
}
</style>
